import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { Helmet } from "react-helmet"

const CollarsPage = () => (
  <Layout>
    
 <div id="lp-header-announcement">
     <span>YOUR CAT'S NEW FAVORTIE COLLAR</span>
     <a>SHOP NOW</a>
     </div>   
<div class="headerMain">
    <div class="block">
    <StaticImage
      src="../images/pawsome-couture.png"
      width={200}
      quality={95}
       />
  </div>
</div>
<div id="banner">
<div class="block">
<div id="banner-heading"> 
    <h1>5 Reasons Cat Owners <br/> Are Now Relying On <br/>These Collars</h1>
    <ul>
    <li>Vet Approved Safety Buckle</li>
    <li>Your Cat's Ticket Home</li>
    <li>100% Safe and Effective</li>  
    </ul>
<a id="banner-btn"><span>LEARN MORE</span></a>

</div>
</div>
</div>

<div id="banner_mob">
<div class="block">
<div id="banner-mob-heading"> 
    <h1>5 Reasons Cat Owners <br/> Are Now Relying On <br/>These Collars</h1>
    <ul>
    <li>Vet Approved Safety Buckle</li>
    <li>Your Cat's Ticket Home</li>
    <li>100% Safe and Effective</li>  
    </ul>
<a id="banner-btn"><span>LEARN MORE</span></a>

</div>
</div>

</div>

<div id="mainContent" class="block">
    <Seo title="Home" />
   
    <p>Welcome to your new Gatsby site.</p>
    <p>Now go build something great.</p>
    <StaticImage
      src="../images/gatsby-astronaut.png"
      width={300}
      quality={95}
      formats={["auto", "webp", "avif"]}
      alt="A Gatsby astronaut"
      style={{ marginBottom: `1.45rem` }}
    />
    <p>
      <Link to="/page-2/">Go to page 2</Link> <br />
      <Link to="/using-typescript/">Go to "Using TypeScript"</Link> <br />
      <Link to="/using-ssr">Go to "Using SSR"</Link> <br />
      <Link to="/using-dsg">Go to "Using DSG"</Link>
    </p>
    </div>
  </Layout>

)

export default CollarsPage
